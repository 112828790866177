import React, { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route, useNavigate, Navigate } from "react-router-dom";
import { Homepage } from "./pages/Homepage";
import { Navbar } from "./pages/Navbar";
import { CallbackHandler } from "./pages/CallbackHandler";
import { Leaderboard } from "./pages/Leaderboard";
import axios from "axios";
import { Test } from "./pages/Test";
import { Servers } from "./pages/Servers";
import { ProtectedRoute } from "./_components/ProtectedRoute";
import { useAuth } from "./_reducers/authentication.reducer";

export const Router: React.FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  const auth = useAuth();
  useEffect(() => {
      axios.get('/api/session').then(res => res.data['logged_in']).then(data => {
        setIsLoggedIn(data)});
      });
  useEffect(() => {
        if(isLoggedIn)
        {
          axios.get('/api/users/me').then(res => auth.dispatch({type: 'LOGIN_SUCCESS', user: {username: res.data['username'], discriminator: res.data['discriminator'], id: parseInt(res.data['id']), avatar_hash: res.data['avatar_hash']}}));
        }
      
      setIsLoading(false);
  },[isLoggedIn]);
   return (
      <BrowserRouter>
        <Navbar /> 
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="/callback" element={<CallbackHandler />} />
          <Route path="/leaderboard" element={<Leaderboard />} />
          <Route path="/test" element={<Test />} />
          <Route path="/servers" element={<ProtectedRoute ><Servers /></ProtectedRoute>}/>
        </Routes>
      </BrowserRouter>
  );
};
