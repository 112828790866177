import React from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { Navigate, NavLink, useNavigate } from "react-router-dom";
import { logout } from "../_slices/authenticationSlice";

import axios from "axios";
import { useAuth } from "../_reducers/authentication.reducer";

export const Navbar: React.FC = () => {
  const navigate = useNavigate();
  const auth = useAuth()
  return (
    <div className="border-b-2 bg-slate-500 border-slate-400">
      <div className="flex sm:justify-between">
        <div className="justify-begin mr-5 invisible sm:visible w-0 sm:w-full">
          <h1 className="px-2 py-2 mr-5 text-blue-100">J.A.R.V.I.S. dashboard</h1>
        </div>
        <nav className="flex justify-start sm:justify-end mr-5">
          <NavLink
            to="/"
            className={({ isActive }) =>
              isActive ? "font-bold text-blue-100 hover:text-blue-200 px-2 py-2 mr-5" : "text-blue-100 hover:text-blue-200 px-2 py-2 mr-5"}
          >
              Home
          </NavLink>
          <NavLink
            to="/leaderboard"
            className={({ isActive }) =>
              isActive ? "font-bold text-blue-100 hover:text-blue-200 px-2 py-2 mr-5" : "text-blue-100 hover:text-blue-200 px-2 py-2 mr-5"}
          >
            Leaderboard
          </NavLink>
          {auth.state.isLoggedIn && 
            (<NavLink
            to="/servers"
            className={({ isActive }) =>
              isActive ? "font-bold text-blue-100 hover:text-blue-200 px-2 py-2 mr-5" : "text-blue-100 hover:text-blue-200 px-2 py-2 mr-5"}
              >
              Your&nbsp;servers
            </NavLink>)
          }
          {!auth.state.isLoggedIn ? (
          <div
            className="text-blue-100 hover:text-blue-200 px-2 py-2 cursor-pointer"
            onClick={async () => {
              let redirect_uri = await axios.get('/api/login').then(data => data.data);
              window.location.href = redirect_uri}
            }
          >
            Login
          </div>): (<div className="text-blue-100 hover:text-blue-200 px-2 py-2 cursor-pointer" onClick={() => {axios.get('/api/logout'); auth.dispatch({type: "LOGOUT", user: null}); navigate('/')}}>Logout</div>)}
        
        </nav>
      </div>
    </div>
  );
};
