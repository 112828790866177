import React from 'react';

class ServerPanel extends React.Component<ServerEntryProps> {
    render() {
        const name = this.props.name;
        const id = this.props.id;
        const icon = this.props.icon;
        return (
            <div>
                <div key={id} className="rounded-xl bg-slate-300 border-gray p-4">
                    <img src={icon} alt="" className="mx-auto rounded-full"/>
                </div>
                <div className="mt-4 text-slate-300 mx-auto justify-center font-semibold">
                    {name}
                </div>
            </div>
        )
    }
}

export default ServerPanel;