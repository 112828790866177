import axios from "axios";
import React, { useEffect, useState } from "react";
import { LeaderboardEntry } from "../_components";

export const Leaderboard: React.FC = () => {
  const handleClick = () => {
    axios.get('/api/guilds/716774093285752962/leaderboard/100/' + leaderboard.length).then(resp => resp.data).then(data => setLeaderboard(leaderboard.concat(data)))
  };
  const [leaderboard, setLeaderboard] = useState<ILeaderboardEntry[]>([]);
  const [leaderboardCount, setLeaderboardCount] = useState<number>(0);
  useEffect(() => {
    axios.get('/api/guilds/716774093285752962/leaderboard/100/0').then(resp => resp.data).then(data => setLeaderboard(data));
    axios.get('/api/guilds/716774093285752962/leaderboard').then(resp => resp.data).then(resp => setLeaderboardCount(resp));
  }, []);
  return (
    <div className="flex-col flex mx-auto items-center">
      <h1 className="text-white justify-center text-center my-5 text-2xl">Leaderboard</h1>
        {
          leaderboard.map(
            (entry: ILeaderboardEntry) => (<LeaderboardEntry key={entry.place} entry={entry} />)
          )
        }
        {leaderboard.length < leaderboardCount ?
          <button className='text-white hover:bg-slate-700 bg-slate-500 text-xl font-semibold py-2 px-4 rounded-lg mx-auto justify-center my-8' onClick={handleClick}>Load more</button> 
          :
          <div className='text-white text-xl font-semibold my-8 mx-auto'>You've reached the end of the leaderboard</div>
        }
    </div>
  );
};
