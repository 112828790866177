import React from "react";

type Props = {entry: ILeaderboardEntry };
export const LeaderboardEntry: React.FC<Props> = ({entry}) => {
    console.log(entry.avatar_url)
    return (
        <div className="bg-slate-300 mb-1 px-5 py-5 rounded-md 2xl:w-2/5 lg:w-4/5 mx-auto flex items-center sm:w-full md:w-5/6 xl:w-3/4 w-full" key={entry.place}>
            <div 
                className={
                    entry.place === 1 ? 
                        "bg-yellow-400 rounded-full w-8 h-8 font-semibold justify-center flex items-center border-slate-900 border" : 
                        (entry.place === 2 ? 
                            "bg-gray-300 rounded-full w-8 h-8 font-semibold justify-center flex items-center border-slate-900 border" : 
                            (entry.place === 3 ? 
                                "bg-yellow-600 rounded-full w-8 h-8 font-semibold justify-center flex items-center border-slate-900 border" : 
                                    "bg-slate-400 rounded-full w-8 h-8 font-semibold justify-center flex items-center border-slate-900 border"))}>
                    <span className="font-bold text-slate-900">
                        {entry.place}
                    </span>
            </div>
            <div className="ml-5 flex items-center justify-between flex-grow">
                <h1 className="text-slate-600 font-semibold text-lg text-left" >
                    {entry.display_name}
                </h1>
                <div className="justify-end flex items-center">
                    <div className="flex divide-x divide-slate-600 mr-5 justify-evenly invisible sm:visible w-0 sm:w-full">
                        <div className="flex flex-col items-center justify-center text-slate-600 pr-2 invisible sm:visible w-0 sm:w-full">
                            <div className="text-xs">
                                PRINTING
                            </div>
                            <div className="font-semibold">
                                {entry.points_printing}
                            </div>
                        </div>
                        <div className="flex flex-col items-center justify-center text-slate-600 px-2 invisible sm:visible w-0 sm:w-full">
                            <div className="text-xs">
                                MODELING
                            </div>
                            <div className="font-semibold">
                                {entry.points_modelling}
                            </div>
                        </div>
                        <div className="flex flex-col items-center justify-center text-slate-600 px-2 invisible sm:visible w-0 sm:w-full">
                            <div className="text-xs">
                                COSPLAY
                            </div>
                            <div className="font-semibold">
                                {entry.points_cosplay}
                            </div>
                        </div>
                        <div className="flex flex-col justify-center items-center text-slate-600 pl-2">
                            <div className="text-xs ">
                                TOTAL
                            </div>
                            <div className="font-semibold">
                                {entry.points}
                            </div>
                        </div>
                    </div>
                    <div className="flex items-center justify-center ">
                        <img src={entry.avatar_url} alt="" className="h-12 w-12 max-w-none rounded-full"/>
                    </div>
                </div>
            </div>
        </div>
    )
}