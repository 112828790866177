import axios from "axios";
import React, { useEffect, useState } from "react";
import ServerPanel from '../_components/ServerPanel';

export const Servers: React.FC = () => {
    const [servers, setServers] = useState<ServerEntryProps[]>([]);
    useEffect(() => {
        axios.get('/api/user/guilds').then(resp => resp.data).then(data => setServers(data));
    }, []);
    return (
        <div className="flex-col flex mx-auto items-center">
            <h1 className="text-white justify-center text-center my-5 text-2xl">Your servers</h1>
            <div className="grid grid-cols-3 gap-8">
                {
                    servers.map(
                        (entry: ServerEntryProps) => (<ServerPanel key={entry.id} id={entry.id} name={entry.name} icon={entry.icon} />)
                    )
                }
            </div>
        </div>
    )
}