import React from 'react';
import { useAuth } from '../_reducers/authentication.reducer';

export const Homepage: React.FC = () => {
  const auth = useAuth();
  const userState = auth.state;
  return (
    <div className='mx-auto items-center flex h-screen place-content-center content-center'>
      <div className='flex-col flex items-center'>
        <h1 className='text-white font-semibold text-5xl text-center'>HI</h1>
        <h1 className='text-white font-semibold text-5xl text-center mt-7'>I'M J.A.R.V.I.S.</h1>
        <h1 className='text-white font-semibold text-5xl text-center mt-7'>I LIVE IN FRANKLY BUILT'S 3D PRINTING AND COSPLAY COMMUNITY</h1>
        <button className='text-white hover:bg-slate-700 bg-slate-500 text-xl font-semibold py-2 px-4 rounded-lg mx-auto justify-center mt-8' onClick={() => (window.location.href = "https://discord.gg/franklybuilt")}>Join discord server</button>
        {userState.isLoggedIn && (userState.user != null && <h1 className="justify-center text-white text-xl font-semibold pt-4">Logged In as {userState.user.username}#{userState.user.discriminator}</h1>)}
      </div>
    </div>
  );
}

export default Homepage;