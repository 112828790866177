import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { get_session, get_token, get_user } from '../_services';
import {login} from "../_slices/authenticationSlice";
import { useDispatch } from "react-redux";
import { useAuth } from "../_reducers/authentication.reducer";
import { authContext } from "../_helpers";

export const CallbackHandler = () => {
    console.log('callback called')
    const [searchParams, setSearchParams] = useSearchParams();
    const [token, setToken] = useState<string>("");
    const code_req = searchParams.get('code');
    const auth = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        const handleLoad = async () => {
            try {
                const token = await get_token(code_req === null ? "" : code_req);
                const res = await get_user();
                if(res.isLoggedIn)
                {
                    auth.dispatch({type: "LOGIN_REQUEST", user: null})
                }
                else{
                    auth.dispatch({type: "LOGIN_FAILURE", user: null})
                }

                navigate('/servers');
            } catch(err) {
                console.error(err);
            }
        }
        handleLoad();
    },[code_req, auth, navigate]);
  return (<div>
      <h1>Redirecting ... </h1>
  </div>);
};
