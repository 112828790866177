import React from "react"
import { Navigate } from "react-router-dom";
import { useAuth } from "../_reducers/authentication.reducer";

type Props = {
    children: JSX.Element;
}
export const ProtectedRoute: React.FC<Props> = ({children}) => {
    const auth = useAuth();
    if(!auth.state.isLoggedIn)
    {
        return <Navigate to="/" />;
    }
    return children;
}