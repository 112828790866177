import axios from "axios";
import { createContext, useContext, useReducer } from "react";
import {get_user} from "../_services";

type Action = {type: "LOGIN_REQUEST", user: IUser|null} | {type: "LOGIN_SUCCESS", user: IUser|null} | {type: "LOGIN_FAILURE", user: IUser|null} | {type: "LOGOUT", user: IUser|null};
type Dispatch = (action: Action) => void;

export function authentication(state: UserState, action: Action) {
    switch (action.type) {
    case "LOGIN_REQUEST":
        return {
        user: action.user,
        isLoggedIn: true
        };
    case "LOGIN_SUCCESS":
        return {
        user: action.user,
        isLoggedIn: true
        };
    case "LOGIN_FAILURE":
        return {
            user: null,
            isLoggedIn: false
        };
    case "LOGOUT":
        return {
            user: null,
            isLoggedIn: false
        };
    default:
        return state
    }
}

export const AuthContext = createContext<{state: UserState; dispatch: Dispatch} | undefined>(undefined);

type Props = {
    children: React.ReactNode
}
export function AuthProvider({children}: Props) {
  const [state, dispatch] = useReducer(authentication, {user: null, isLoggedIn: false})
  // NOTE: you *might* need to memoize this value
  // Learn more in http://kcd.im/optimize-context
  const value = {state, dispatch}
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

export function useAuth(){
    const context = useContext(AuthContext);
    if (context === undefined) {
        throw new Error('useAuth must be used within a AuthProvider')
    }
    return context;
}

export {}