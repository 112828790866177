import axios from "axios";

export async function get_user(){
    const isLoggedIn: boolean = await axios.get('/api/session').then(res => res.data['logged_in']);
    let user: IUser|null = null;
    console.log("get_user")
    if(isLoggedIn)
    {
        user = await axios.get('/api/users/me')
    }
    return {user, isLoggedIn};
}

export async function get_token(code_req: string) {
    const token = await axios.get('/api/callback', {headers: { code: code_req}, withCredentials: true}).then(res => res.data['refresh_token'])//.then(token => setToken(res.data['refresh_token']))
    return token;
}

export async function get_session()
{
    const loggedIn = await axios.get('/api/session', {withCredentials: true}).then(res => res.data['logged_in']);
    return loggedIn;
}